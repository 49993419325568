.header {
	background-color: var(--lighter-dark-purple);
	width: 100%;
	height: 70px;
	display: block;
	/* margin-top: -20px; */
	z-index: 101;
}

.header-links {
	float: right;
	padding-right: 30px;
	font-weight: 200;
	font-size: 1.5rem;
	margin-top: -10px;
}

.header-element {
	margin-left: 20px;
	cursor: pointer;
	transition-duration: 0.2s;
}

.header-element:hover {
	color: var(--true-purple);
	/* text-decoration: underline;
	text-underline-offset: 10px; */
}

.header-mobile {
	display: none;
}

@media screen and (max-width: 950px) {
	.header-links {
		display: none;
	}

	.header-mobile {
		display: block;
		position: absolute;
		top: 0px;
		z-index: 100;
		width: 100%;
		height: 70px;
		z-index: 99;
		background-color: var(--lighter-dark-purple);
	}

	.logo-title-mobile {
		display: block;
		position: absolute;
		top: 20px;
		left: 20px;
		z-index: 100;
		width: 2.5rem;
		z-index: 100;
		background-color: var(--lighter-dark-purple);
	}

	.hamburger-icon {
		display: block;
		position: absolute;
		top: 20px;
		right: 20px;
		z-index: 100;
		width: 2.5rem;
		cursor: pointer;
		font-size: 2rem;
		z-index: 100;
	}

	.header-mobile-title {
		display: block;
		position: absolute;
		top: 20px;
		width: 100%;
		text-align: center;
		font-size: 1.5rem;
		font-weight: bold;
		z-index: 100;
	}

	.header-mobile-links {
		display: block;
		position: absolute;
		top: 70px;
		width: 100%;
		text-align: center;
		font-size: 1.5rem;
		font-weight: bold;
		z-index: 90;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
	}

	.header-mobile-element {
		width: 100%;
		transition-duration: 0.2s;
		background-color: var(--dark-purple);
		margin: 0px;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.mobile-header-link-element {
		margin-left: 20px;
		margin-right: 20px;
	}

	.header-mobile-backdrop {
		/* display: none; */
		position: absolute;
		top: 10vh;
		left: 0px;
		width: 100vw;
		height: 90vh;
		background-color: black;
		z-index: 70;
		opacity: 0.3;
	}

	.header-mobile-dropdown {
		/* display: none; */
		position: absolute;
		top: -150vh;
		width: 100%;
		text-align: center;
		font-size: 1.5rem;
		font-weight: bold;
		z-index: 80;
		transition-duration: 0.5s;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
	}

	.header-mobile-dropdown-active {
		top: 0px;
	}
}
