.side-links {
	position: sticky;
	background-color: red;
	display: inline-block;
	margin-top: -246px;
	margin-left: 20px;
	height: 0px;
	bottom: 0px;
}

.line-up {
	position: static;
	width: 3px;
	margin-left: calc(1.1rem - 1.5px);
	background-color: rgba(255, 255, 255, 0.438);
	border-top-left-radius: 1px;
	border-top-right-radius: 1px;
}

.side-link-element {
	font-size: 2.2rem;
	color: rgba(255, 255, 255, 0.438);
	cursor: pointer;
	transition-duration: 0.2s;
	padding-bottom: 20px;
	font-weight: 100;
	position: relative;
}

.side-link-element:hover {
	color: var(--true-purple);
	transform: translateY(-3px);
}

@media screen and (max-width: 950px) {
	.side-links {
		display: none;
	}
}
